import React from 'react'
import MainLayout from '../../layouts/mainlayout/MainLayout';
import PageHeader from '../../components/pageheader/PageHeader';

const timings = () => {
	return (
		<MainLayout title="About Us | Church Timings">
			<PageHeader title="About Us" subnav="about" />
			<section className="row general-section">
				<h2 className="section-head mb-3">Church Timings</h2>
				<div className='col-sm-12 col-md-6 col-lg-6 ps-5'>
					<strong className='ms-3 fs-5'>Sunday Worship Services:</strong>
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Preparation prayer:</em></td>
								<td className='ps-3'>5:30am - 5:55am</td>
							</tr>
							<tr>
								<td><em>1st Service:</em></td>
								<td className='ps-3'>6:00am - 7:15am</td>
							</tr>
							<tr>
								<td><em>2nd Service:</em></td>
								<td className='ps-3'>8:30am - 9:45am</td>
							</tr>
							<tr>
								<td><em>3rd Service:</em></td>
								<td className='ps-3'>11:00am - 12:15pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-3 fs-5'>Evangelism Ministry:</strong> <br />
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Tuesdays:</em></td>
								<td className='ps-3'>10:30am - 1:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-3 fs-5'>Care Cell Prayer:</strong> <br />
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Wednesdays:</em></td>
								<td className='ps-3'>7:00pm - 8:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-3 fs-5'>Fasting Prayer:</strong> <br />
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Fridays:</em></td>
								<td className='ps-3'>10:30am - 12:30pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-3 fs-5'>Men's Fellowship:</strong> <br />
					<strong className='ms-4 ps-1 fs-6'>Stephen Group:</strong>
					<table className='ms-5 mb-1'>
						<tbody>
							<tr>
								<td><em>Fridays:</em></td>
								<td className='ps-3'>8:00pm -9:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-4 ps-1 fs-6'>Joshua Group:</strong>
					<table className='ms-5 mb-1'>
						<tbody>
							<tr>
								<td><em>Thursdays:</em></td>
								<td className='ps-3'>8:00pm -9:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-4 ps-1 fs-6'>Peter Group:</strong>
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Wednesdays:</em></td>
								<td className='ps-3'>8:00pm -9:00pm</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='col-sm-12 col-md-6 col-lg-6 ps-5'>
					<strong className='ms-3 fs-5'>Sunday Bible classes:</strong>
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>During 1st Service:</em></td>
								<td className='ps-3'>6:00am - 7:15am</td>
							</tr>
							<tr>
								<td><em>During 2nd Service:</em></td>
								<td className='ps-3'>8:30am - 9:45am</td>
							</tr>
							<tr>
								<td><em>During 3rd Service:</em></td>
								<td className='ps-3'>11:00am - 12:15pm</td>
							</tr>
							<tr>
								<td><em>Outer Area Sunday Schools:</em></td>
								<td className='ps-3'>4:00pm - 5:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-3 fs-5'>Teen Groups:</strong> <br />
					<strong className='ms-4 ps-1 fs-6'>Teen Boys:</strong>
					<table className='ms-5 mb-1'>
						<tbody>
							<tr>
								<td><em>Sundays:</em></td>
								<td className='ps-3'>5:30pm - 6:30pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-4 ps-1 fs-6'>Teen Girls:</strong>
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Sundays:</em></td>
								<td className='ps-3'>4:30pm - 5:30pm</td>
							</tr>
							<tr>
								<td><em>Sundays:</em></td>
								<td className='ps-3'>6:00pm - 7:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-3 fs-5'>Youth Groups:</strong> <br />
					<strong className='ms-4 ps-1 fs-6'>Youth Boys:</strong>
					<table className='ms-5 mb-1'>
						<tbody>
							<tr>
								<td><em>Saturdays:</em></td>
								<td className='ps-3'>6:00pm - 7:00pm</td>
							</tr>
						</tbody>
					</table>
					<strong className='ms-4 ps-1 fs-6'>Youth Girls:</strong>
					<table className='ms-5 mb-4'>
						<tbody>
							<tr>
								<td><em>Sundays:</em></td>
								<td className='ps-3'>6:00pm - 7:00pm</td>
							</tr>
						</tbody>
					</table>
				</div>
			</section>
		</MainLayout>
	)
}

export default timings